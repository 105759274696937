<template>
  <div>
    <HeaderPanel
      title="Promotions"
      :filter="filter"
      placeholder="Search Name, Ref."
      @search="handleSearch"
      @sidebar="sidebarFilter"
    >
      <template v-slot:content-dropdown>
        <b-dropdown-item
          @click="$router.push('promotions/promotions-sap-discount/0')"
        >
          <span>Promotion</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="$router.push('promotions/promotions-items-discount/0')"
        >
          <span>Items Discount</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="$router.push('promotions/promotions-header-discount/0')"
        >
          <span>Header Discount</span>
        </b-dropdown-item>
        <b-dropdown-item @click="openModalImport">
          <span>Import Promotions</span>
        </b-dropdown-item>
      </template>
    </HeaderPanel>
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
      />
    </div>
    <b-modal
      v-model="modalImport"
      no-close-on-backdrop
      centered
      title="Import Promotions"
      hide-footer
    >
      <div>
        <b-row class="no-gutters">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              text="*Please upload only file .xlsx less than 10 MB"
              isRequired
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="form.file_name"
              v-model="form.excel_file"
              :v="$v.form.excel_file"
              id="uploadfile"
            />
          </b-col>
          <b-col cols="12">
            <InputText
              textFloat="Email"
              placeholder="Email"
              type="text"
              name="email"
              isRequired
              v-model="form.email"
              :v="$v.form.email"
              :isValidate="$v.form.email.$error"
          /></b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button size="md" class="btn-cancel" @click="modalImport = false"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importPromotions"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @searchAll="handleSearch"
      @clearFilter="clearFilter"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <b-form-group>
          <template #label>
            <b>Promotion Type:</b><br />
            <b-form-checkbox
              v-model="promotionsTypeAll"
              aria-describedby="Status"
              aria-controls="Status"
              @change="promotionToggle"
            >
              Select All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              class="ml-4"
              id="checkbox-group-2"
              stacked
              :options="promotionsTypeOptions"
              v-model="filter.promotionTypeId"
              :aria-describedby="ariaDescribedby"
            >
            </b-form-checkbox-group>
          </template>
        </b-form-group>
        <div class="mt-3">
          <div>
            <div class="font-weight-bold mb-2">Valid From</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.startDate"
                ref="startDate"
                @input="handleMinDate"
                placeholder="Please Select Date"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="font-weight-bold mb-2">Valid To</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.endDate"
                :min-datetime="filter.startDate"
                placeholder="Please Select Date"
                ref="endDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </SideBarFilter>
    <router-view></router-view>
  </div>
</template>
<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import { required, email } from "vuelidate/lib/validators";
import HeaderPanel from "@/components/HeaderPanel";
import Table from "@/components/promotions/Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  name: "Promotions",
  components: {
    HeaderPanel,
    SideBarFilter,
    Table,
    UploadFileV2,
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        excel_file: {
          required,
        },
      },
    };
  },
  data() {
    return {
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      modalImport: false,
      excel: "",
      form: {
        email: "",
        file_name: "",
        excel_file: "",
      },
      fields: [
        {
          key: "promotion_ref",
          label: "Promotions Ref.",
          tdClass: "w-15",
        },
        {
          key: "name",
          label: "Promotions Name",
          class: "text-center",
          tdClass: "w-25",
        },
        {
          key: "discount",
          label: "Discount",
        },
        {
          key: "promotion_type",
          label: "Promotions Type",
          tdClass: "w-75px",
        },
        {
          key: "start_datetime",
          label: "Valid Form",
        },
        {
          key: "end_datetime",
          label: "Valid To",
        },

        { key: "status", label: "Status" },
        { key: "actions", label: "" },
      ],
      items: [],
      promotionsTypeOptions: [
        { value: 1, text: "Promotion" },
        { value: 2, text: "Item Discount" },
        { value: 3, text: "Header Discount" },
      ],
      filter: {
        page: 1,
        take: 10,
        search: "",
        promotionTypeId: [1, 2, 3],
        status: [1, 0],
        startDate: null,
        endDate: null,
      },
      promotionsTypeAll: true,
      isDisable: false,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "filter.promotionTypeId"(newValue) {
      if (newValue.length === 0) {
        this.promotionsTypeAll = false;
      } else if (newValue.length === this.promotionsTypeOptions.length) {
        this.promotionsTypeAll = true;
      } else {
        this.promotionsTypeAll = false;
      }
    },
  },
  methods: {
    openModalImport() {
      this.form = {
        email: this.$cookies.get("back_office_admin_email"),
        file_name: "",
        excel_file: "",
      };
      this.$v.form.$reset();
      this.modalImport = true;
    },
    async importPromotions() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$EventBus.$emit("showLoading");
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_API}/import/importexcelpromotion`,
        this.form
      );
      this.$EventBus.$emit("hideLoading");
      if (res.data.result) {
        this.modalImport = false;
        this.getData();
        this.successAlert();
      } else {
        this.errorAlert(res.data.message);
      }
    },
    handleMinDate() {
      let diff = this.$moment(this.filter.endDate).diff(
        this.$moment(this.filter.startDate),
        "min"
      );
      if (diff < 0) this.filter.endDate = "";
    },
    async getData() {
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_API}/promotion/list`,
        this.filter
      );

      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterPage(filter) {
      this.filter = filter;

      this.getData();
    },
    promotionToggle(checked) {
      this.filter.promotionTypeId = checked
        ? this.promotionsTypeOptions.map((el) => el.value)
        : [];
    },
    handleSearch() {
      this.getData();
    },
    clearFilter() {
      this.filter = {
        page: 1,
        take: 10,
        search: "",
        promotionTypeId: [1, 2, 3],
        status: [1, 0],
        startDate: null,
        endDate: null,
      };
      this.getData();
    },
    onFileChange(file) {
      this.form.file_name = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.form = {
        file_name: "",
        excel_file: "",
      };
    },
  },
};
</script>
<style lang="css">
.w-15 {
  width: 15% !important;
}
</style>
